export const POST_SLUG: string =
  process.env.HOSTING_DOMAIN === "travelvision" ? "articles" : "posts";
export const LINE_ADD_FRIEND_URL = "https://lin.ee/tlg2Tnc";

export const PostSearchGAParams = {
  utm_campaign: "line_ta1",
  utm_medium: "organic",
  utm_source: "tarip",
};

export const PostTrackingLinkDomains = ["liff.line.me"];
