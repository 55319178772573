import * as React from "react";
import { css } from "aphrodite";
import { RegionTagListStyle } from "main/javascripts/components/molecules/Post/RegionTagList/RegionTagListStyle";
import { Region } from "main/javascripts/models/Region";
import { POST_SLUG } from "main/javascripts/constants/PostConstants";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";

export interface IProps {
  regions: Region[];
  disableLink?: boolean;
}

export const RegionTagList: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { regions, disableLink } = props;
  const tagStyle: any = disableLink
    ? [RegionTagListStyle.regionTag, RegionTagListStyle.noHover]
    : RegionTagListStyle.regionTag;
  return (
    <div className={css(RegionTagListStyle.regionTagList)}>
      {regions.map(({ id, name }: { id: number; name: string }) =>
        disableLink ? (
          <span key={name} className={css(tagStyle)}>
            {name}
          </span>
        ) : (
          <a
            href={`${PATH_SCOPE}/${POST_SLUG}?region=${id}`}
            key={name}
            className={css(tagStyle)}
          >
            {name}
          </a>
        )
      )}
    </div>
  );
};
