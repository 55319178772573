import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";

export interface IStyle {
  regionTagList: any;
  regionTag: any;
  noHover: any;
}

export const RegionTagListStyle: IStyle = StyleSheet.create({
  regionTagList: {
    padding: `${space.atom0_5x} 0.375rem 0`,
  },
  regionTag: {
    color: textColor.secondaryDarkColor,
    backgroundColor: borderColor.primaryLightColor,
    textDecoration: "none",
    margin: `0 ${space.atom} ${space.atom} 0`,
    padding: `${space.atom0_5x} ${space.atom}`,
    display: "inline-block",
    borderRadius: borderRadius.message,
    fontSize: fontSize.caption,
    lineHeight: lineHeight.caption3,
    transition: "color 0.4s ease, background 0.4s ease",
    ":hover": {
      color: textColor.primaryDarkColor,
      backgroundColor: borderColor.secondaryLightColor,
    },
  },
  noHover: {
    ":hover": {
      color: textColor.secondaryDarkColor,
      backgroundColor: borderColor.primaryLightColor,
    },
  },
});
