import * as React from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Post } from "main/javascripts/types/post";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { POST_SLUG } from "main/javascripts/constants/PostConstants";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";

export interface IProps {
  tags: Post.Tag[];
}

export const PopularTagList: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { tags } = props;
  const { t } = useTranslation(["component"]);

  if (tags.length === 0) {
    return null;
  }
  return (
    <div css={blockStyle}>
      <div css={titleStyle}>{t("post.popularTags")}</div>
      <div css={listStyle}>
        {tags.map(({ id, name }: { id: number; name: string }) => (
          <a
            href={`${PATH_SCOPE}/${POST_SLUG}/tags/${id}`}
            key={name}
            css={tagStyle}
          >
            <span css={hashStyle}>#</span>
            {name}
          </a>
        ))}
      </div>
    </div>
  );
};

const blockStyle = css`
  padding: ${space.atom1_5x} ${space.atom2x};
  background-color: #fff;
`;
const titleStyle = css`
  font-size: ${fontSize.subHeading};
  font-weight: ${fontWeight.medium};
  padding: 0 ${space.atom2x} ${space.atom1_5x};
  margin: 0 -${space.atom2x};
  border-bottom: 2px solid;
  letter-spacing: ${letterSpacing.title};
`;
const listStyle = css`
  padding: ${space.atom3x} 0 0;
`;
const tagStyle = css`
  color: ${textColor.primaryDarkColor};
  text-decoration: none;
  padding: 0 ${space.atom3x} ${space.atom1_5x} 0;
  display: inline-block;
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption3};
  transition: color 0.4s ease;
  &:hover {
    color: ${textColor.secondaryDarkColor};
  }
`;
const hashStyle = css`
  opacity: 0.3;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.body};
  padding-right: ${space.atom0_5x};
`;
