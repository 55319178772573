import * as React from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Post } from "main/javascripts/types/post";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { TagList } from "./TagList";
import { POST_SLUG } from "main/javascripts/constants/PostConstants";

export interface IProps {
  rankings: Post.Post[];
}

export const RankingList: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { rankings } = props;
  const { t } = useTranslation(["component"]);

  if (rankings.length === 0) {
    return null;
  }
  return (
    <div css={blockStyle}>
      <div css={titleStyle}>{t("post.rankingList")}</div>
      <div css={listStyle}>
        {rankings.map((post) => (
          <a
            href={`${PATH_SCOPE}/${POST_SLUG}/${post.id}`}
            key={post.id}
            css={rankingStyle}
          >
            {post.featuredImage && (
              <div css={imageBlockStyle}>
                <img
                  css={imageStyle}
                  src={post.featuredImage.url}
                  alt={post.title}
                />
              </div>
            )}
            <div css={contentStyle}>
              <div css={postTitleStyle}>{post.title}</div>
              {post.tags && post.tags.length > 0 && (
                <div css={tagListStyle}>
                  <TagList tags={post.tags} disableLink />
                </div>
              )}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

const blockStyle = css`
  padding: ${space.atom1_5x} ${space.atom2x};
  background-color: #fff;
`;
const titleStyle = css`
  font-size: ${fontSize.subHeading};
  font-weight: ${fontWeight.medium};
  padding: 0 ${space.atom2x} ${space.atom1_5x};
  margin: 0 -${space.atom2x};
  border-bottom: 2px solid;
  letter-spacing: ${letterSpacing.title};
`;
const listStyle = css`
  margin: ${space.atom} 0;
  padding: ${space.atom2x} 0 0;
`;
const rankingStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${textColor.primaryDarkColor};
  text-decoration: none;
  margin: 0 0 ${space.atom2x} 0;
  padding: 0 0 ${space.atom2x} 0;
  border-bottom: 1px solid ${borderColor.primaryLightColor};
  transition: color 0.4s ease;
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }
  &:hover {
    color: ${textColor.secondaryDarkColor};
  }
`;
const imageBlockStyle = css`
  width: 22%;
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;
const imageStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const contentStyle = css`
  width: 74%;
  ${moreThanBreakpoint("desktop")} {
    width: 73%;
  }
`;
const postTitleStyle = css`
  font-size: ${fontSize.mediumHeading};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.mediumHeadline2};
  ${moreThanBreakpoint("desktop")} {
    font-size: ${fontSize.body};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.body2};
  }
`;
const tagListStyle = css`
  padding: ${space.atom0_5x} 0 0;
`;
