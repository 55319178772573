import * as React from "react";
import { css } from "@emotion/react";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { Post } from "main/javascripts/types/post";
import { POST_SLUG } from "main/javascripts/constants/PostConstants";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";

export interface IProps {
  tags: Post.Tag[];
  disableLink?: boolean;
}

export const TagList: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { tags, disableLink } = props;

  return (
    <div css={listStyle}>
      {tags.map((tag) =>
        disableLink ? (
          <span key={tag.id} css={tagStyle}>
            <span css={hashStyle}>#</span>
            {tag.name}
          </span>
        ) : (
          <a
            key={tag.id}
            css={tagStyle}
            href={`${PATH_SCOPE}/${POST_SLUG}/tags/${tag.id}`}
          >
            <span css={hashStyle}>#</span>
            {tag.name}
          </a>
        )
      )}
    </div>
  );
};

const listStyle = css`
  padding: 0;
`;
const tagStyle = css`
  color: ${textColor.secondaryDarkColor};
  text-decoration: none;
  padding: 0 ${space.atom2_5x} ${space.atom} 0;
  display: inline-block;
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption3};
  transition: color 0.4s ease;
`;
const hashStyle = css`
  opacity: 0.4;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.body};
  padding-right: ${space.atom0_5x};
`;
